<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search/>
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col class="ma-0 pa-0">
            <v-card class="ma-0 pa-5 w-100">
              <!-- Header -->
              <h1 class="mb-5">Create a Story</h1>
              <div>
                <!-- Intro -->
                <div>
                  <p>Creating a story for a veteran is the first step in honoring that veteran with Honor Tag. Stories save all the information about the life of your veteran in one easy-to-view place.</p>
                  <p>A story can contain personal narratives, peer tributes, media and deployment information to reveal the many facets of a servicemember's experience.</p>
                </div>
                <!-- Login Prompt -->
                <div v-if="!isAuth">
                  <v-btn color="blue darken-3 white--text" to="/login">Log In</v-btn>
                  <v-btn color="blue darken-3 white--text" to="/register" class="ml-2">Register</v-btn>
                </div>
                <!-- Form -->
                <v-form v-else
                  @submit.prevent="submit"
                  ref="form"
                  v-model="valid"
                  class="mb-10 mt-10"
                >
                  <!-- Profile Picture -->
                  <v-row class="ma-0">
                    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
                    <v-col class="col-12 col-sm-4 pa-0">
                      <v-file-input
                        v-model="mainImageFile"
                        @change="previewMainImage"
                        label="Main Story Image"
                        filled
                        prepend-icon="mdi-camera"
                        accept="image/*"
                      ></v-file-input>
                      <v-img v-if="mainImageFile" :src="mainImageURL" class="w-100"></v-img>
                    </v-col>
                    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="firstName" label="First Name" :rules="requiredFieldRule"></v-text-field>
                      <v-select v-model="branch" label="Military Branch" :rules="requiredFieldRule" :items="require('@/assets/data/branches.json')" return-object/>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="lastName" label="Last Name"></v-text-field>
                      <v-select v-model="rank" label="Highest Grade/Rank" :items="!branch ? [] : require(`@/assets/data/${branch.rankPath}/ranks.json`)" return-object/>
                    </v-col>
                  </v-row>
                  <v-checkbox v-model="isVerified" label="This veteran is a verified member of the United States military." :rules="requiredFieldRule"></v-checkbox>
                  <v-checkbox v-model="isMyStory" label="This story is about me."></v-checkbox>
                  <v-btn
                    type="submit"
                    :loading="loading"
                    class="blue darken-3 mb-3 white--text"
                  >
                    Create Story
                  </v-btn>
                  <!-- Additional Info -->
                  <p>You will be able to add more details and photos to the story after creating it.</p>
                  <div class="error--text mt-3">{{ error }}</div>
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Search from '@/components/Search'

  export default {
    name: 'StoryCreate',

    components: {
      Search
    },
    
    computed: {
      ...mapGetters([
        'isAuth',
        'userUID'
      ])
    },

    data () {
      return {
        branch: '',
        requiredFieldRule: [
          v => !!v || 'Required field.'
        ],
        error: '',
        firstName: '',
        isMyStory: false,
        isVerified: false,
        lastName: '',
        loading: false,
        mainImageFile: null,
        mainImageURL: '',
        rank: '',
        storyForSelf: false,
        valid: false
      }
    },

    methods: {
      previewMainImage () {
        this.mainImageURL = this.mainImageFile ? URL.createObjectURL(this.mainImageFile) : ''
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const storyData = {
            firstName: this.firstName,
            firstNameInsensitive: this.firstName.toLowerCase(),
            lastName: this.lastName,
            lastNameInsensitive: this.lastName.toLowerCase(),
            manager: this.userUID,
            serviceTerms: [{ branch: this.branch, rank: this.rank }]
          }
          this.$store.dispatch('addStory', { storyData, mainImageFile: this.mainImageFile })
          .then(() => {
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
